/// <reference types="typescript" />

const historyAction: INetteAjaxCallbacks = {
	prepare: function (settings: INetteAjaxSetting) {
		settings.headers = settings.headers || {};
		settings.headers['source-address'] = window.location.href;
	}
};

$.nette.ext('snippets').after(function (jEl: JQuery) {
	if (jEl.attr('id') === 'snippet--page-container') {
		//noinspection JSSuspiciousNameCombination
		window.scrollTo(jEl.offset().top, 0);
	}
});

if (Modernizr.history) {
	historyAction.success = function (payload) {
		let url = payload.url;
		let title = payload.title;

		if (title === undefined) {
			title = document.title;
		}

		if (url) {
			history.pushState({url: payload.url}, title, payload.url);
		}
	};
}

$.nette.ext('history', historyAction);
